import axios from 'axios'
import {
  compressImage
} from '../modules/CompressImage'
import imgTypeChange from '../modules/imgChange'
// 访问线上api时axios发送跨域请求时需要设置这个参数
const config = {
  timeout: 60000,
  withCredentials: true,
}
//本地调试端口6281  发布6280
const baseURL = {
  development:'https://web.yuntixi.cn',  //'http://1.14.169.193:6024',  //http://1.14.169.193:6024  //http://1.14.169.193:92 
  production: 'https://web.yuntixi.cn',  //http://101.33.249.65:88  //http://192.168.2.125:8520 //https://web.yuntixi.cn
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config.baseURL = baseURL.development
} else if (process.env.NODE_ENV === 'production') {
  // 生产环境
  config.baseURL = baseURL.production
}
const axiosInstance = axios.create(config)
// params 拼接在url的查询字符串参数 
// data body参数
const get = function (api, data) {
  return axiosInstance({
    method: 'GET',
    url: api,
    params: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      TenantId: JSON.parse(sessionStorage.getItem("tenantId"))?JSON.parse(sessionStorage.getItem("tenantId")):0
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
const deleteMethod = function (api, data) {
  return axiosInstance({
    method: 'DELETE',
    url: api,
    params: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      TenantId: JSON.parse(sessionStorage.getItem("tenantId"))
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}

const del = function (api, data) {
  return axiosInstance({
    method: 'DELETE',
    url: api,
    data: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      TenantId: JSON.parse(sessionStorage.getItem("tenantId")),
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}

const put = function (api, data) {
  return axiosInstance({
    method: 'PUT',
    url: api,
    data: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      TenantId: JSON.parse(sessionStorage.getItem("tenantId"))
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
const getUserCode = function (api) {
  return axiosInstance({
    method: 'GET',
    url: api,
    responseType: 'blob',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
    },
  })
}
const post = function (api, data) {
  return axiosInstance({
    method: 'POST',
    url: api,
    data: data,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      'Content-Type': 'application/json',
      TenantId: JSON.parse(sessionStorage.getItem("tenantId"))
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
const postImg = async function (api, data) {
  let file = await imgTypeChange(data)
  let newfile = await compressImage(file)
  if (newfile.size > 10000) {
    newfile = await compressImage(newfile)
  }
  var formData = new FormData()
  formData.append('file', newfile, newfile.name);
  return axiosInstance({
    method: 'POST',
    url: api,
    data: formData,
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(window.sessionStorage.getItem('token')),
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.data)
    .catch((error) => error)
}
axiosInstance.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status == 404
    ) {
      window.location = '/#/404'
      return
    }
    if (error.response.status == 500
    ) {
      window.location = '/#/500'
      return
    }
    return Promise.reject(error.response)
  }
)

export { get, post, getUserCode, deleteMethod, put, baseURL, postImg, del }
