// 将base64转换为blob
import Compressor from 'image-compressor.js'
export async function compressImage(file) {
  if (!file) {
    return
  }

  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.2,
      success(result) {
        result = new File([result], result.name, { type: result.type })
        resolve(result)
      },
      error(err) {
        console.log(err.message)
        reject(err.message)
      },
    })
  })
}
