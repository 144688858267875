function imgToCanvas(image) {
  let canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height
  canvas.getContext('2d').drawImage(image, 0, 0)
  return canvas
}
function canvasToImg(canvas, type = 'jpg') {
  return new Promise((resolve) => {
    canvas.toBlob((image) => {
      resolve(image)
    }, `image/${type}`)
  })
}
function getImg(fn, file) {
  return new Promise((resolve) => {
    const imgFile = new FileReader()
    imgFile.readAsDataURL(file)
    imgFile.onload = function (e) {
      const image = new Image()
      image.src = e.target.result
      image.onload = async function () {
        resolve(await fn(image))
      }
    }
  })
}
async function imgTypeChange(file) {
  return await getImg(async function (image) {
    let imgName = file.name.split('.')[0]
    const can = imgToCanvas(image)
    const blobImg = await canvasToImg(can, 'jpeg')
    return new Promise((resolve) => {
      resolve(new File([blobImg], `${imgName}.jpg`, { type: blobImg.type }))
    })
  }, file)
}
export default imgTypeChange
